<template>
  <div
    id="LayoutDefault"
    class="Layout Layout-Default"
  >
    <!-- Toaster -->
    <HaToaster ref="haToaster" />
    <!-- Header -->
    <HaHeaderPublic
      :config-env="configEnv"
      @connect-request="openLoginModale()"
      @disconnect="onLogOut"
      @on-search="handleSearch"
    />
    <!-- Login: Refacto > utiliser la mire d'authentification HA -->
    <ModalLogin
      v-if="displayModalLogin"
      @close="displayModalLogin = false"
    />
    <!-- Content -->
    <main>
      <slot />
    </main>
    <!-- Footer -->
    <HaFooter />
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  createError,
  useRouter,
  onBeforeMount,
  onMounted,
  useNuxtApp,
  useRoute,
  navigateTo
} from '#imports'
import { useStore } from 'vuex'
import {
  HaToaster,
  HaHeaderPublic,
  useHeaderPublic,
  useHeaderSearch,
  useNotifications,
  useTracking
} from '@ha/components-v3'
import HaFooter from '@/components/Footer/LightFooter.vue'
import useLogin from '@/composables/useLogin'
import useCookies from '@/composables/useCookies'
import useConfigEnv from '@/composables/useConfigEnv'
import ModalLogin from '@/components/Modal/ModalLogin.vue'
import { isUserConnected } from '@/services/auth.service'
import useSegment from '@/composables/useSegment'
import useEditMode from '@/composables/useEditMode'
import useSearch from '~/domains/search/composables/search.composable'


const { haToaster } = useNotifications()
const { tracking } = useTracking()
const { setUser, setOrganizations, setHeader } = useHeaderPublic()
const { isAllowedSearchFromHeader, city, isCityCleared } = useHeaderSearch()
const { onLogOut } = useLogin()
const { getAccessToken, cookies } = useCookies()
const store = useStore()
const router = useRouter()
const configEnv = useConfigEnv()
const { trackIdentify } = useSegment()
const { editMode } = useEditMode()
const { runWithContext } = useNuxtApp()
const route = useRoute()
const displayModalLogin = ref(false)
onBeforeMount(async () => {
  const token = getAccessToken()
  if (!token) {
    store.commit('user/REVOKE_USER')
  }
  try {
    if (isUserConnected(cookies)) {
      await store.dispatch('user/fetchUser')
      setUser(store.getters['user/getMe']())
      trackIdentify()
      const hasUserOrganization =
        store.getters['user/getUserOrganizations']()?.length
      if (hasUserOrganization) {
        setOrganizations(store.getters['user/getUserOrganizations']())
      }
    }
  } catch (err) {
    console.error(err)
    const statusCode = (err.response && err.response.status) || 500
    const statusMessage = err.response && err.response.statusText
    throw createError({ statusCode, statusMessage })
  }

  if (editMode.value) {
    const { organization } = route.params

    if (isUserConnected(cookies)) {
      const isAdmin = store.getters['user/isAdmin'](organization)
      if (!isAdmin) {
        runWithContext(() =>
          navigateTo(`/associations/${organization}`)
        )
      }
      await store.dispatch('organizations/fetchOrganizationTypes')
    } else {
      runWithContext(() =>
        navigateTo(`/associations/${organization}`)
      )
    }
  }
})

const { store: storeAlgolia, currentTabIndexes, setStoreToUrlQuery, updateQueriesAndFetch } = useSearch()
const filtersHeader = { ...storeAlgolia.filters, query: storeAlgolia.search }
setHeader(router, tracking, filtersHeader)

onMounted(() => {
  isAllowedSearchFromHeader.value = false
})

const openLoginModale = () => {
  displayModalLogin.value = true
}

const handleSearch = data => {
  storeAlgolia.setSearch(data.query)
  // to avoid to have query key in filters
  delete data.query
  // set filters from url  to storeAlgolia
  storeAlgolia.setFilters(data)
  storeAlgolia.setParams({ page: undefined })

  setStoreToUrlQuery()
  // refetch
  updateQueriesAndFetch(currentTabIndexes.value)
  if (isCityCleared.value) {
    city.value = ''
    isCityCleared.value = false
  }
}
</script>

<style lang="scss">
@import './styles/default';
</style>
